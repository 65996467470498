import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { BadgesRegular, BadgesText, BadgesCounter, BadgesInOtherElements, BadgesSizes } from "../../examples/components/Badges";
export const query = graphql`
  query BadgeQuery {
    metadata: componentMetadata(displayName: { eq: "Badge" }) {
      displayName
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Badges scale to match the size of the immediate parent element by using
  relative font sizing and em units.
    </Overview>
    <CodeBlock title="Regular Badges" code={BadgesRegular} mdxType="CodeBlock">
  Add any of the below mentioned modifier classes to change the appearance of a
  badge.
    </CodeBlock>
    <CodeBlock title="Text Badges" code={BadgesText} mdxType="CodeBlock">
  Add a <code>text-'{"theme-color"}'</code> variant to your badge for a text
  that maintains the same spacing, just without the background color.
    </CodeBlock>
    <CodeBlock title="Counter Badges" code={BadgesCounter} mdxType="CodeBlock">
  Use the <code>pill</code> modifier class to make badges more rounded (with a
  larger <code>border-radius</code> and additional horizontal{" "}
  <code>padding</code>).
    </CodeBlock>
    <CodeBlock title="Badges in other elements" code={BadgesInOtherElements} mdxType="CodeBlock">
  Badges can be inserted into other elements. Just be sure to utilize our
  utility classes to position your badges appropriately.
    </CodeBlock>
    <CodeBlock title="Badge Sizes" code={BadgesSizes} mdxType="CodeBlock">
  By default Badges scale according to their immediate parent element but there
  are also large and small options available, use classes{" "}
  <code>badge-sm, badge-lg</code>.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="badges-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.metadata} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      